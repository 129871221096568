import { deleteJSON, getJSON, patchJSON, postJSON } from "."

const orderServer = {
    async create(data){
        const response = await postJSON('/orders', data);
        return response
    },

    async browse(page = 1, dates = ['', ''], table = '', waiter = ''){
        // console.log(`/orders?page=${page}&status=${status}&dateFrom=${dates[0]}&dateTo=${dates[1]}`)
        const response = await getJSON(`/orders?page=${page}&dateFrom=${dates[0]}&dateTo=${dates[1]}&table=${table}&waiter=${waiter}`)
        return response
    },

    async get(id){
        const response = await getJSON(`/orders/${id}`)
        return response
    },

    async addItems(id, data){
        const response = await patchJSON(`/orders/${id}/add-items`, data)
        return response
    },

    async update(id, data){
        const response = await patchJSON(`/orders/${id}`, data)
        return response
    },

    async reports(data){
        const response = await postJSON(`/orders/reports`, data)
        return response
    },

    async delete(id, authCode){
        const response = await deleteJSON(`/orders/${id}?code=${authCode}`)
        return response
    },

    async deleteAll(ids, code){
        const response = await postJSON(`/orders/delete/all`, {ids, code})
        return response
    },

    async saveNote(id, note){
        const response = await patchJSON(`/orders/${id}/note`, {note})
        return response
    }
}

export default orderServer
import axios from "axios";


// export const baseUrl = 'http://127.0.0.1:8000/api';
export const baseUrl = 'https://app.the90spalace.com/api';

const headers = {
    Acecpt: 'application/json',
    'Content-type': 'application/json'
}



export const getJSON = async (url) => {
    const jsonData = localStorage.getItem('_auth')
    const userData = JSON.parse(jsonData)
    if(userData){
        headers['Authorization'] = `Bearer ${userData.token}`;
    }
    const response = await axios.get(baseUrl + url, {headers});
    return response.data
}


export const postJSON = async (url, data) => {
    const jsonData = localStorage.getItem('_auth')
    const userData = JSON.parse(jsonData)
    if(userData){
        headers['Authorization'] = `Bearer ${userData.token}`;
    }
    const response = await axios.post(baseUrl + url, data, {headers});
    return response.data
}

export const patchJSON = async (url, data) => {
    const jsonData = localStorage.getItem('_auth')
    const userData = JSON.parse(jsonData)
    if(userData){
        headers['Authorization'] = `Bearer ${userData.token}`;
    }
    const response = await axios.patch(baseUrl + url, data, {headers});
    return response.data
}

function getFormData(object) {
    const jsonData = localStorage.getItem('_auth')
    const userData = JSON.parse(jsonData)
    if(userData){
        headers['Authorization'] = `Bearer ${userData.token}`;
    }
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
}

export const postMultipart = async (url, data) => {
    const jsonData = localStorage.getItem('_auth')
    const userData = JSON.parse(jsonData)
    if(userData){
        headers['Authorization'] = `Bearer ${userData.token}`;
    }
    const formData = getFormData(data)
    // console.log(data)
    const response = await axios.post(baseUrl + url, formData, {...headers, 'Content-Type': 'multipart/form-data'});
    return response.data
}

export const patchMultipart = async (url, data) => {
    const jsonData = localStorage.getItem('_auth')
    const userData = JSON.parse(jsonData)
    if(userData){
        headers['Authorization'] = `Bearer ${userData.token}`;
    }
    const formData = getFormData(data)
    // console.log(data)
    const response = await axios.patch(baseUrl + url, formData, {...headers, 'Content-Type': 'multipart/form-data'});
    return response.data
}


export const deleteJSON = async (url) => {
    const jsonData = localStorage.getItem('_auth')
    const userData = JSON.parse(jsonData)
    if(userData){
        headers['Authorization'] = `Bearer ${userData.token}`;
    }
    const response = await axios.delete(baseUrl + url, {headers})
    return response.data
}
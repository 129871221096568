import React from 'react'
import { NavLink } from 'react-router-dom'
import waiterServer from '../../lib/server/waiter'

const ResultWaiter = ({waiter}) => {
    return (
        <div className='flex gap-3 items-center'>
            <img src={waiterServer.image(waiter.id)} className='w-12 h-12 rounded-full object-cover object-center' alt="" />
            <div className='flex items-center gap-6'>
                <NavLink to={`/management/waiters/${waiter.id}`}>{waiter.name}</NavLink>
                <NavLink className={`flex items-center gap-2 py-1.5 px-3 rounded-md border hover:bg-zinc-100`} to={`/orders/waiter/${waiter.id}`}>
                    <span>Orders</span>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                        </svg>
                    </span>
                </NavLink>
            </div>
        </div>
    )
}

export const WaitersSearchResult = ({waiters}) => {
  return (
    <div className='mb-10'>
        <p className='text-lg font-medium border-b border-zinc-300 mb-6'>Waiters</p>
        <div className='flex flex-col gap-3'>
            {waiters.map(item => <ResultWaiter key={item.id} waiter={item} />)}
        </div>
    </div>
  )
}

import React, { useContext, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import orderServer from '../../lib/server/order'
import { useReactToPrint } from 'react-to-print'
import { SettingContext } from '../../contexts/SettingContext'

// let totalQuantity = 0;

function formatDateTime(dateString) {
    const date = new Date(dateString);
    return date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    });
}

export const OrderPrint = ({price = true}) => {

    const [orderData, setOrderData] = useState(null)
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [tax, setTax] = useState();
    const componentRef = useRef()
    const {id} = useParams()
    const {settings} = useContext(SettingContext)
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
    useEffect(() => {
        if(!id){
            return
        }
        (async () => {
            const response = await orderServer.get(id)
            setOrderData(response)
            setTax(JSON.parse(response.tax))
            let _totalQuantity = 0;
            for(let i in response.items){
                _totalQuantity = _totalQuantity + response.items[i].pivot.quantity
            }
            setTotalQuantity(_totalQuantity)
        })()
    }, [id])


    const ComponentToPrint = () => {
        return (
            <>
                <style type="text/css" media="print">{"@page { size: 80mm 276mm; }"}</style>
                <div ref={componentRef} className='py-4 px-2 text-sm'>
                    <div className='w-fit mx-auto text-center mb-2'>
                        <div>
                            <img src="/logo.jpg" className='h-32 mx-auto' alt="" />
                        </div>
                        <p className='text-sm'>3rd Floor, 22 Sahid Surya Sen Road Mohan Mall, Berhampore, India, West Bengal</p>
                        <p className='text-sm mb-3'>Call: +91 89727 09594</p>
                        <p className='test-sm'>GST NO: {settings?.GST_NO}</p>
                    </div>
                    <div className='text-sm'>
                        {price ? <p className='flex gap-2'><span className='w-24 block'>Order Id:</span> {orderData.id}</p>: null}
                        {price ? <p className='flex gap-2'><span className='w-24 block'>Order Date:</span>  {formatDateTime(orderData.created_at)}</p>: null}
                        <p className='flex gap-2'><span className='w-24 block'>Table:</span>  {orderData.table.table_code}</p>
                    </div>
                    <p className='line-clamp-1 overflow-hidden'>---------------------------------------------------------------</p>
                    <div className={`flex gap-2 ${!price ? 'justify-between' : ''}`}>
                        <p className='w-32'>Items</p>
                        <p className={`w-12 ${!price ? 'text-right' : '' }`}>Qty.</p>
                        {price ? <p className='w-12'>Rate</p>: null}
                        {price ? <p className='w-12 text-right'>Value</p>: null}
                    </div>
                    <p className='line-clamp-1 overflow-hidden'>---------------------------------------------------------------</p>
                    {orderData.items.map(item => {
                        return (
                            <div className={`flex gap-2 mb-2 ${!price ? 'justify-between' : ''}`} key={item.id}>
                                <p className='w-32'>{item.title}</p>
                                <p className={`w-12 ${!price ? 'text-right' : '' }`}>{item.pivot.quantity}</p>
                                {price ? <p className='w-12'>{item.pivot.price}</p>: null}
                                {price ? <p className='w-12 text-right'>{item.pivot.price * item.pivot.quantity}</p>: null}
                            </div>
                        )
                    })}
                    <p className='line-clamp-1 overflow-hidden'>---------------------------------------------------------------</p>
                    <div className='flex justify-between items-center'>
                        <p>Total Quantity</p>
                        <p className='text-right'>{totalQuantity}</p>
                    </div>
                    {!price ? <div className='mt-4'>
                        <p className='font-medium'>Note: {orderData.note}</p>
                    </div> : null}
                    {price ?<> 
                    <div className='flex justify-between items-center'>
                        <p>Sub Total</p>
                        <p className='text-right'>{orderData.total}</p>
                    </div>
                    {tax.SGST[0] ? <div className='flex justify-between items-center'>
                        <p>SGST @ {tax.SGST[0]}%</p>
                        <p className='text-right'>{tax.SGST[1]}</p>
                    </div> : null}
                    {tax.CGST[0] ? <div className='flex justify-between items-center'>
                        <p>CGST @ {tax.CGST[0]}%</p>
                        <p className='text-right'>{tax.CGST[1]}</p>
                    </div> : null}
                    <div className='flex justify-between items-center'>
                        <p>Discount</p>
                        <p className='text-right'>-{orderData.discount}</p>
                    </div>
                    <div className='flex justify-between items-center font-bold text-lg mb-12'>
                        <p>Total</p>
                        <p className='text-right'>{Math.round(orderData.final).toFixed(2)}</p>
                    </div>
                    <p className='text-center'>Thanks for visit ...</p></>
                    : null}
                </div>
            </>
        )
    }

  return (
    <div>
        {/* <button onClick={handlePrint}></button> */}
        <div className='flex justify-center my-8 w-full' onClick={handlePrint}>
            <button className="flex items-center px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-600 rounded-lg hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6.72 13.829c-.24.03-.48.062-.72.096m.72-.096a42.415 42.415 0 0 1 10.56 0m-10.56 0L6.34 18m10.94-4.171c.24.03.48.062.72.096m-.72-.096L17.66 18m0 0 .229 2.523a1.125 1.125 0 0 1-1.12 1.227H7.231c-.662 0-1.18-.568-1.12-1.227L6.34 18m11.318 0h1.091A2.25 2.25 0 0 0 21 15.75V9.456c0-1.081-.768-2.015-1.837-2.175a48.055 48.055 0 0 0-1.913-.247M6.34 18H5.25A2.25 2.25 0 0 1 3 15.75V9.456c0-1.081.768-2.015 1.837-2.175a48.041 48.041 0 0 1 1.913-.247m10.5 0a48.536 48.536 0 0 0-10.5 0m10.5 0V3.375c0-.621-.504-1.125-1.125-1.125h-8.25c-.621 0-1.125.504-1.125 1.125v3.659M18 10.5h.008v.008H18V10.5Zm-3 0h.008v.008H15V10.5Z" />
                </svg>

                <span className="mx-1">Print</span>
            </button>
        </div>
        {orderData ? <ComponentToPrint /> : null}        
      </div>
  )
}

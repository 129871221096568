import React from 'react'
import { NavLink } from 'react-router-dom'

const ResultTable = ({table}) => {
    return (
        <div className='flex gap-3 items-center'>
            <div className='text-2xl'>
                <i className="fa-solid fa-chair"></i>
            </div>
            <div className='flex items-center gap-6'>
                <NavLink to={`/management/tables/${table.id}`}>{table.table_code}</NavLink>
                <NavLink className={`flex items-center gap-2 py-1.5 px-3 rounded-md border hover:bg-zinc-100`} to={`/orders/table/${table.id}`}>
                    <span>Orders</span>
                    <span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 19.5 15-15m0 0H8.25m11.25 0v11.25" />
                        </svg>
                    </span>
                </NavLink>
            </div>
        </div>
    )
}

export const TablesSearchResult = ({tables}) => {
  return (
    <div>
        <p className='text-lg font-medium border-b border-zinc-300 mb-6'>Tables</p>
        <div className='flex flex-col gap-3'>
            {tables.map(item => <ResultTable key={item.id} table={item} />)}
        </div>
    </div>
  )
}
